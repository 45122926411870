import { StopCard, UserBlock } from '@app/shared/components';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState, } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import 'swiper/css';
import { motion } from 'framer-motion';
import { useRecoilState, useRecoilValue } from 'recoil';
import { atom_tourState } from '@app/shared/state/tour.atom';
import { atom_activeStopState } from '@app/shared/state/active-stop.atom';
import { useLeafletContext } from '@react-leaflet/core';
import { Stop } from '@app/shared/models/tour.type';
import { atom_openedStopState } from '@app/shared/state/opened-stop.atom';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import { StopDetails } from '@app/features/map/stop-details/StopDetails';
import { atom_showModalContent } from '@app/shared/state/show-content-modal.atom';
import useGeolocation from 'react-hook-geolocation';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import { useGlobalHooks } from '@app/core/hooks/global-hooks';
import { atom_DownloadModeState } from '@app/shared/state/download-mode';
import Cube from '@app/shared/components/cube/Cube';
// import Cube from '@app/shared/components/cube/cube';

type TourOverlayLayerProps = {
	children?: React.ReactNode;
};

const wait = async (time: number) => {
	return new Promise((res: any, rej) => {
		setTimeout(() => {
			res();
		}, time);
	});
};

export const TourOverlayLayer: React.FC = React.memo<TourOverlayLayerProps>(
	function TourOverlayLayer(_p) {
		const geolocation = useGeolocation({
			enableHighAccuracy: true,
			maximumAge: 15000,
			timeout: 60000
		});
		const leaflet = useLeafletContext();
		const { t, i18n } = useTranslation();
		const _g = useGlobalHooks();
		const navigate = useNavigate();
		const location = useLocation();
		const [swiper, setSwiper] = useState<SwiperClass | null>(null);
		const state_TourState = useRecoilValue(atom_tourState);
		const [state_showModal, setState_showModal] = useRecoilState(
			atom_showModalContent
		);
		const [state_isDownloadMode] = useRecoilState(atom_DownloadModeState)
		const [isInfoClicked, setIsInfoClicked] = useState<boolean>(false);
		const [state_activeStopState, setState_activeStopState] = useRecoilState(
			atom_activeStopState
		);
		const [state_openedStopState, setState_openedStopState] = useRecoilState(
			atom_openedStopState
		);
		const [lang, setLang] = useState({});
		useEffect(() => {
			if (lang !== (i18n as any).language) {
				setLang((i18n as any).language);
			}
		}, [(i18n as any).language]);

		const isHebrew = () => ((i18n as any).language !== 'en' ? true : false);

		const getTitleColor = () => {
			return (
				state_TourState?.config?.['titleColor'] || _g.theme.palette.primary.main
			);
		};

		useEffect(() => {
			if (state_openedStopState?.id)
				navigate(`${location.pathname}?stop=${state_openedStopState?.id}`);
		}, [state_openedStopState]);

		useEffect(() => {
			if (!location.search) setState_openedStopState(undefined);
		}, [location, state_TourState]);

		useEffect(() => {
			if (
				!(
					state_activeStopState?.type === 'bigStop' ||
					state_activeStopState?.type === 'business' ||
					!!state_activeStopState?.show_on_slider
				)
			) {
				return;
			}
			const zoomLevels = state_isDownloadMode ? [15, 16, 17, 18] : [18];
			(async () => {
				for (const zoomLevel of zoomLevels) {
					if (state_isDownloadMode) {
						await wait(500);
					}
					leaflet.map.flyTo(
						[
							state_activeStopState?.stop_location.latitude,
							state_activeStopState?.stop_location.longitude
						],
						zoomLevel,
						{
							duration: 0.3
						}
					)
				}
			})()

			// const activeStopIndex = state_TourState?.stops.findIndex(
			//   (stop) => stop.id === state_activeStopState?.id
			// );
			// if (activeStopIndex !== undefined) swiper?.slideTo(activeStopIndex);
		}, [state_activeStopState, state_isDownloadMode]);

		const handleSwipe = (index: number) => {
			const nextSwipe = state_TourState?.stops?.filter(
				// (stop) => stop.type === "bigStop" || stop.type === "business"
				stop => !!stop.stop_index || !!stop.show_on_slider
				// (x) => true
			);
			setState_activeStopState(nextSwipe?.[index]);
		};

		const handleStopCardClick = (stop: Stop) => {
			setState_openedStopState(stop);
		};

		const flyToDeviceLocation = () => {
			leaflet.map.flyTo([geolocation.latitude, geolocation.longitude], 18, {
				duration: 0.5
			});
		};

		const renderModalContent = () => {
			if (!isInfoClicked && !state_showModal) {
				return;
			}
			if (isInfoClicked) {
				return (
					<Box>
						<Box className="flex justify-center">
							<Typography variant="h5" className="p-5 pb-2">{t('on_tour.dguide_describe.how_to_use_the_tour')}</Typography>
						</Box>
						<Typography className="flex align-center p-1 justify-center" style={{ background: 'white' }}>Powered by

							<a href="https://maslol.io" target="_blank" style={{ marginLeft: 5, color: 'blue' }} rel="noreferrer"> Maslol</a>
						</Typography>
						<ReactPlayer
							// onError={(err) => alert(JSON.stringify(err))}
							// style={{ maxHeight: '70vh' }}
							width="100%"
							height={'70vh'}
							url={state_TourState?.exp_video?.split('&token=')?.[0]}
							controls
						/>
						<Box className="flex justify-center" margin={'15px'}>
							<Button
								size="small"
								style={{ color: '#000' }}
								onClick={() => {
									window.open(
										state_TourState?.config?.whatsappLink ||
										'https://wa.me/message/FD25J6MAVRP5E1'
									);
								}}
							>
								{t('on_tour.dguide_describe.still_have_a_problem')}
							</Button>
						</Box>

					</Box>
				);
			}
			return state_activeStopState?.type === 'smallStop' ||
				state_activeStopState?.type === 'WCStop' ||
				state_activeStopState?.type === 'waterStop' ||
				state_activeStopState?.type === 'noEntrance' ||
				state_activeStopState?.type === 'restaurant' ||
				state_activeStopState?.type === 'coffee' ||
				state_activeStopState?.type === 'shoppingCenter' ||
				state_activeStopState?.type === 'hospital' ||
				state_activeStopState?.type === 'streetFood' ||
				state_activeStopState?.type === 'localBusiness' ||
				state_activeStopState?.type === 'starStop' ? (
					<Box
						style={{
							paddingTop: 20,
							maxHeight: '90vh',
							minHeight: '60vh',
							width: '80vw',
							direction: isHebrew() ? 'rtl' : 'ltr',
							textAlign: 'center'
						}}
					>
						<Typography variant="h4">{state_activeStopState?.s_title}</Typography>
						<StopDetails />
					</Box>
				) : (
					<Box
						style={{
							maxHeight: '90vh',
							paddingTop: 20,
							minHeight: '60vh',
							width: '80vw',
							direction: isHebrew() ? 'rtl' : 'ltr',
							textAlign: 'center'
						}}
					>
						<Typography variant="h5"> {t('on_tour.stop.next_stop')}</Typography>
						<Typography variant="h4">{state_activeStopState?.s_title}</Typography>
						<Typography>{state_activeStopState?.diraction_text}</Typography>
						<Box className="flex justify-center m-5">
							<img
								style={{ maxHeight: '35vh' }}
								src={
									state_activeStopState?.loc_pics?.[0]?.url?.split('&token=')?.[0]
								}
								alt="image"
							/>
						</Box>
						<Button
							onClick={() => {
								handleStopCardClick(state_activeStopState as Stop);
								setState_showModal(false);
							}}
							className="m-4"
						>
							{t('on_tour.stop.show_me_content')}
						</Button>
					</Box>
				);
		};

		return (
			<div className="fragment">
				<Box sx={{ zIndex: 500 }} className="fixed top-0 w-full">
					{/* <Typography className="flex align-center p-1 justify-center" style={{ background: "white" }}>Powered by

						<a href="https://maslol.io" target="_blank" style={{ marginLeft: 5 }}> Maslol</a>
					</Typography> */}

					<Box
						className="flex align-center p-1 justify-between"
						style={{
							background: getTitleColor(),
							minHeight: 70,
							direction: 'rtl',
							alignItems: 'center'
						}}
					>
						<Box style={{ padding: 5 }}>
							<UserBlock
								omitText={true}
								title={t('pre_start_tour.your_guide')}
								name={''}
								image_url={
									state_TourState?.tour_guide?.profile_image.split(
										'&token'
									)?.[0]
								}
							/>
						</Box>

						<Box className="mt-3">
							<Typography
								style={{ textAlign: 'center' }}
								color="white"
								variant="h6"
							>
								{state_TourState?.title}
							</Typography>
							<Typography
								className="flex justify-center text-center"
								color="white"
								variant="caption"
							>
								{state_TourState?.main_sentense}
							</Typography>
						</Box>
						<Button className="p-2 w-min min-w-min" variant="text">
							<Box
								onClick={() => {
									window.confirm(t('on_tour.go_back_tour') as string) && window.location.reload()
								}}
								className="flex justify-center"
							>
								<i
									style={{ background: 'white', padding: 5, borderRadius: 100 }}
									className="fa-solid fa-arrow-circle-left scale-150"
								></i>
							</Box>
						</Button>
					</Box>
					<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box>
							{geolocation.latitude && !geolocation.error && <Button
								className="p-2 w-min min-w-min"
								variant="text"
								onClick={flyToDeviceLocation}
							>
								<i className="text-3xl fa-solid fa-location-crosshairs"></i>
							</Button>}
						</Box>
						<Box>
							<Button
								className="p-2 w-min min-w-min"
								variant="text"
								onClick={() => setIsInfoClicked(true)}
							>
								<i
									style={{
										margin: 6,
										padding: 2,
										background: 'white',
										borderRadius: 100
									}}
									className="fa-solid fa-info-circle scale-150"
								></i>
							</Button>
						</Box>
					</Box>
				</Box>
				<div
					key={isHebrew() + 'test'}
					style={{ direction: isHebrew() ? 'rtl' : 'ltr' }}
				>
					<Box
						className="fixed bottom-0 w-full pointer-events-auto"
						sx={{
							alignItems: !isHebrew() ? 'flex-start' : 'flex-end',
							background:
								'linear-gradient(0deg, #00000050 100%, #00000000 100%)',
							zIndex: 500
						}}
					>
						<Swiper
							spaceBetween={10}
							slidesPerView={1.3}
							touchMoveStopPropagation
							onSwiper={_swiper => setSwiper(_swiper)}
							onSlideChange={swiper => handleSwipe(swiper.activeIndex)}
							style={{ overflow: 'visible' }}
						>
							{state_TourState?.stops
								.filter(
									// (stop) => stop.type === "bigStop" || stop.type === "business"
									stop => !!stop.stop_index || !!stop.show_on_slider
								)
								.map((stop, idx) => (
									<SwiperSlide
										style={{
											direction: isHebrew() ? 'rtl' : 'ltr',
											alignSelf: 'flex-end'
										}}
										key={'stopstop' + stop.id}
										className="relative"
									>
										<Box
											// className="flex flex-col-reverse items-end absolute bottom-full right-0 mb-1mr-4"
											sx={{ width: '100%' }}
										>
											<Button
												style={{
													display: 'block',
													textAlign: 'right',
													borderTopLeftRadius: isHebrew() ? 10 : 0,
													borderBottomLeftRadius: isHebrew() ? 10 : 0,
													borderTopRightRadius: isHebrew() ? 0 : 10,
													borderBottomRightRadius: isHebrew() ? 0 : 10
												}}
												className="p-1 px-8 mb-1"
												disabled={stop.id !== state_activeStopState?.id}
												onClick={() => setState_showModal(true)}
											>
												<Typography variant="subtitle2" color="white">
													{stop.stop_index
														? t('on_tour.cards.stop_number', {
															i: stop.stop_index
														}) :
														stop.slider_item_title ? stop.slider_item_title
															: t('on_tour.cards.stop_in_business')}
												</Typography>
												<Typography variant="body2" color="white">
													{stop.type !== 'smallStop'
														? t('directions.click_here_for_direction')
														: t('on_tour.stop.show_me_content')}
												</Typography>
											</Button>
										</Box>
										<motion.div
											whileTap={{ scale: 0.9 }}
											className="mb-4"
											// style={{ marginInlineStart: 12 }}
											dragElastic
										>
											<Box className="flex-1">
												{stop.all_media.find(
													media => media.type === 'audio/mpeg'
												) && (
													<audio
														controls
														className="h-7 mt-1 mb-2"
														style={{ width: '70vw' }}
													>
														<source
															src={
																stop.all_media.find(
																	media => media.type === 'audio/mpeg'
																)?.url
															}
															type="audio/mpeg"
														/>
															Your browser does not support the audio element.
													</audio>
												)}
											</Box>
											<StopCard
												stop={stop}
												onClick={() =>
													stop.id === state_activeStopState?.id &&
													handleStopCardClick(stop)
												}
												sx={{ width: '100%', height: '15vh' }}
												title={stop.s_title}
												image_src={
													stop?.loc_pics?.length
														? stop.loc_pics[0].url?.split('&token=')?.[0]
														: ''
												}
												categories={stop.tags}
											/>
										</motion.div>
									</SwiperSlide>
								))}
						</Swiper>
					</Box>
				</div>
				<Modal
					open={isInfoClicked || state_showModal}
					onClose={() => {
						setState_showModal(false);
						setIsInfoClicked(false);
					}}
					center
				>
					{renderModalContent()}
				</Modal>
			</div >
		);
	}
);
