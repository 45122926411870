import { Box } from '@mui/material';
import React from 'react';
// eslint-disable-next-line
import { Stop } from '../models/tour.type';


export function NavigationOptions({
	stop,
	omitWaze,
}: {
  stop: Stop;
  omitWaze?: boolean;
}) {
	return (
		navigator.onLine ?  (
			<Box className="flex gap-8 justify-center">
				{!omitWaze && (
					<img
						src="/images/waze-icon.png"
						onClick={() =>
							window.open(
								`https://waze.com/ul?ll=${
									stop?.location?.latitude || stop?.stop_location?.latitude
								},${stop?.location?.longitude || stop?.stop_location?.longitude}`
							)
						}
						width={20}
					/>
				)}
				<img
					onClick={() => {
						window.open(
							`https://www.google.com/maps/dir//${
								stop?.location?.latitude || stop?.stop_location?.latitude
							},${stop?.location?.longitude || stop?.stop_location?.longitude}`
						);
					}}
					src="/images/google-maps-icon.png"
					width={20}
				/>
			</Box>
      
		) : <div></div>
	);
}
