import React, { useEffect } from 'react';
import { GlobalProviders } from '@app/core/GlobalProviders';
import { RoutingLayer } from '@app/core/routing/RoutingLayer';
import '@app/core/initialize-services/InitializeServices';
import 'react-responsive-modal/styles.css';
import './i18n/config';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
function App() {
	const [isNotSupportedBrowser, setIsNotSupportedBrowser] = React.useState(false);
	const { t } = useTranslation();	

	useEffect(() => {
		const userAgent = navigator.userAgent.toLowerCase();
		const isChromeBased = /chrome|chromium|crios|edg|opera/.test(userAgent);
		const isSafari = /safari/.test(userAgent) && !/chrome|chromium|crios|edg|opera/.test(userAgent);
		const isFirefox = /firefox/.test(userAgent);

		if (!isChromeBased && !isSafari && !isFirefox) {
			setIsNotSupportedBrowser(true);
		}
		
		function handlePopState() {
			if (window.confirm(t('on_tour.go_back_tour') as string)) {
				window.location.reload();
			}
		}

		window.addEventListener('popstate', handlePopState);

		window.history.pushState(null, '', window.location.pathname);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	
	return (
		isNotSupportedBrowser ?
			( 
				<div style ={{margin: 24,paddingTop: '50%'}}>
					<div style={{ display: 'flex',  justifyContent: 'center', alignItems: 'center' }}>
						<Typography variant="h4">{t('on_tour.not_supported_browser')}</Typography>
					</div>
					<div style={{ display: 'flex',textAlign:'center', justifyContent: 'center', alignItems: 'center' }}>		
						<Typography variant="body1">{t('on_tour.not_supported_browser_description')}</Typography>	
					</div>
				</div>
			)
			: 
			(<div className="App">
				<GlobalProviders>
					<RoutingLayer />
				</GlobalProviders>
			</div>)
	);
}

export default App;
